import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/mobile-web-introduction",
  "date": "2014-12-01",
  "title": "MOBILE WEB INTRODUCTION",
  "author": "admin",
  "tags": ["development", "css", "mobile"],
  "featuredImage": "feature.jpg",
  "excerpt": "What is the mobile web? The mobile web refers to access to the world wide web, i.e. the use of browser-based Internet services, from a handheld mobile device, such as a smartphone or a feature phone, connected to a mobile network or other wireless network."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The web itself is not only targeted for the desktop. It should be accessible from any device, as in `}<strong parentName="p">{` the internet of things`}</strong>{`. Tim Berners-Lee once said "The primary design principle underlying the Web's usefulness and growth is universality"`}</p>
    <h2>{`Web Design`}</h2>
    <p>{`Web design consists of three things:`}</p>
    <ul>
      <li parentName="ul">{`Mobile Design`}</li>
      <li parentName="ul">{`Adaptive Design`}</li>
      <li parentName="ul">{`Responsive Design`}</li>
    </ul>
    <h2>{`Mobile Design with Fluid Layout`}</h2>
    <p>{`A fluid layout is a layout that uses proportional values as a measuring unit for blocks of content, images, or any other item that is a part of the WordPress theme. This allows the web page to stretch and contract relative to the user's screen size.`}</p>
    <h2>{`Why proportional layouts are essential for responsive designs?`}</h2>
    <p>{`Whilst media queries are powerful, we are now aware of some limitations. Any fixed-width design, using only media queries to adapt for different viewports, will merely 'snap' from one set of CSS media query rules to the next with no linear progression between the two.`}</p>
    <p>{`Instead, we want to create a design that flexes and looks good on all viewports, not just particular ones specified in a media query. Which concludes that we need to switch our fixed, pixel-based layouts to fluid, proportional ones. This will enable elements to scale relative to the viewport until one media query or another modifies the styling.`}</p>
    <h2>{`Proportional layouts and media queries`}</h2>
    <p><strong parentName="p">{`Ethan Marcotte`}</strong>{` wrote the definitive article on Responsive Web Design at `}<strong parentName="p">{`A List Apart`}</strong>{` – new ways to create web pages that offered the best of both worlds: a way to have a fluid flexible design based on a proportional layout, whilst being able to limit how far elements could flex with media queries. Putting them together forms the core of a responsive design, creating something truly greater than the sum of its parts.`}</p>
    <h2>{`A formula to remember`}</h2>
    <p><strong parentName="p">{`Ethan Marcotte`}</strong>{` provided a simple formula for converting fixed width pixels into proportional percentages:`}</p>
    <p>{`target ÷ context = result`}</p>
    <p><strong parentName="p">{`target`}</strong>{` target font size
`}<strong parentName="p">{`context`}</strong>{` font size of containing element`}</p>
    <p>{`Example:`}</p>
    <p><inlineCode parentName="p">{`30px ÷ 10px = 3em`}</inlineCode></p>
    <p>{`Let's see this formula in action:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h1 a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #6C564B;
}
`}</code></pre>
    <p>{`Let's convert the font-size into proportional percentages, provided context being 30px.`}</p>
    <p><inlineCode parentName="p">{`14px ÷ 30px = 0.4666667em`}</inlineCode></p>
    <p>{`The result will look as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h1 a {
    font-size: 0.46666667em; /* 14px/30px */
    text-transform: uppercase;
    text-decoration: none;
    color: #6C564B;
}
`}</code></pre>
    <p>{`Note that there's no need to round.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      